@import '../../index.scss';

.home {
  width: 100%;
  height: 100%;

  .navbar {
    width: 100%;
    padding: 10px;
    text-align: center;
    box-shadow: 0 3px 3px rgb(0 0 0 / 25%);
    z-index: 21;
    position: relative;
    background-color: $lightbackgroundColor;
  }

  .containers {
    display: flex;
    height: calc(100% - 50px);
  }

  .containers-table-opened {
    height: calc(100% - 320px) !important;
  }

  .table {
    // // height: 0;
    // // visibility: hidden;
    // width: 100%;
    // transition: 0.2s;
    // position: absolute;
    // bottom: 45px;
    // z-index: 100;
    height: 0;
    visibility: hidden;
    transition: .2s;
  }

  .table-opened {
    visibility: visible;
    position: absolute;
    width: 100%;
    bottom: 45px;
    z-index: 90;
    height: 255px;
    tbody {
      overflow-y: auto !important;
    }
  }

  .map-container {
    // height: calc(100vh - 89.5px);
    margin: 0;
    width: 100%;
    height: 100%;
    position: relative;

    .legend-container {
      display: none;
      position: absolute;
      bottom: 20px;
      right: 10px;
      background-color: $primaryColor;
      z-index: 3;
      padding: 7px;
      height: 11%;
      border-radius: 10px;
      // width: 18%;
      // max-width: 145px;
      // min-width: 145px;
    }

  }

  .map-container-panoclose {
    // height: calc(100vh - 51px);
    height: 100%;
    position: relative;
    width: 100%;
  }

  .panorama-container {
    // height: calc(100% - 10px);
    height: 100%;
  }

  .panorama-container2 {
    // height: calc(100% - 10px);
    height: 100%;
  }

  .panorama-container-with-table-opened {
    // height: calc(100% - 450px);
    height: 100%;
  }

  .panorama-container2-with-table-opened {
    // height: calc(100% - 450px);
    height: 100% !important;
  }

  .invisible-panorama-container {
    display: none;
  }

  .close-multi-display {
    display: none;
  }

  .p-splitter-gutter {
    z-index: 2;
    background-color: $secondaryColor;

    .p-splitter-gutter-handle {
      background-color: $secondaryColor;
    }
  }

  .multidisplay-pano-splitter {
    .p-splitter-panel {
      height: calc(100vh - 91px);
    }
  }

  .multidisplay-and-miniscreen-pano-splitter {
    height: 100% !important;
  }

  .multi-splitter-layout-close {
    visibility: hidden !important;
  }

  .mini-screen {
    z-index: 10;
    position: absolute;
    right: 0;
    bottom: 44px;
    width: 200px !important;
    height: 200px !important;
    margin-right: 15px;
    margin-bottom: 10px;
    border: 4px solid #20202a;
  }

  .mini-screen-table-opened {
    z-index: 10;
    position: absolute;
    right: 1px !important;
    bottom: 300px;
    width: 200px !important;
    height: 200px !important;
    margin-right: 15px;
    margin-bottom: 10px;
    border: 4px solid #20202a;
  }
  .mobile-share-button{
    display: flex;
    border: 1px solid #64B5F6;
    box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.2);
    opacity: 1;
    border-radius: 2px;
    position: absolute;
    right: 0px;
    top: 0px;
    transform: translate(-53%, -80%);
    width: 28px;
    height: 29px;
    padding: 3px 0px 0px 3px;
    z-index: 15;
    color: #e5e5e5;
    cursor: pointer;
  }
  .mobile-switch-button {
    display: flex;
    // background-color: #20202a;
    border: 1px solid #64B5F6;
    box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, .2);
    opacity: 1;
    border-radius: 2px;
    position: absolute;
    left: -28px;
    
    // top: 188px;
    // transform: translate(-50%, -50%);
    margin-top: 0px;
    transform: translate(-53%, -80%);
    
    width: 28px;
    height: 26px;
    padding: 2px;
    z-index: 15;
    color: #e5e5e5;
    cursor: pointer;
  }
  
  .play-button {
    width: 16px;
    height: 16px;
    // box-sizing: content-box;
    // padding: 0 1px;
    box-sizing: content-box;
    padding: 3px 4px 1px;
    &.right-margin {
        margin-right: 8px;
    }
    &.left-margin {
        margin-left: 8px;
    }
    &.bigger {
        width: 10px;
        padding: 0;
    }
    &.much-bigger {
        height: 100%;
        padding: 4px 4px;
        width: 28px;
    }
  }

  .mobile-switch-button2 {
    display: flex;
    border: 1px solid #64B5F6;
    box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.2);
    opacity: 1;
    border-radius: 2px;
    position: absolute;
    left: 10px;
    padding: 1px;
    z-index: 15;
    color: #e5e5e5;
    cursor: pointer;
    top: 30px;
    height: 26px;
   /*  display: flex;
    border: 1px solid #64B5F6;
    box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.2);
    opacity: 1;
    border-radius: 2px;
    position: absolute;
    left: 10px;
    bottom: 0px;
    transform: translate(20%, 40%);
    width: 28px;
    height: 26px;
    padding: 1px;
    z-index: 15;
    color: #e5e5e5;
    cursor: pointer; */
}
.slider-text{
  background-color: rgba(44, 44, 59, 0.04);
  color: rgb(100, 181, 246);
  height: 22px;
  width: 24px;
  text-align: center;
  border: none;
  margin-left: 180px;
  z-index: 15;
  padding: 16px;
  position: absolute;
  // background-color: rgb(44 44 59 / 4%);
  // color: rgb(100, 181, 246);
  // height: 22px;
  // width: 24px;
  // /* text-align: center; */
  // border: none;
  // margin-bottom: -10px;
  // margin-left: 192px;
  // padding: 3px;
}
.mobile-slider {
/*   display: flex;
  border: 1px solid #64B5F6;
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.2);
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  left: 20px;
  bottom: 4px;
  transform: translate(20%, 60%);
  width: 140px;
  height: 20px;
  padding: 4px;
  z-index: 15;
  color: #e5e5e5;
  cursor: pointer; */
  display: flex;
  border: 1px solid #64B5F6;
  box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, 0.2);
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  left: 20px;
  top: 25px;
  transform: translate(20%, 40%);
  width: 140px;
  height: 20px;
  padding: 3px;
  z-index: 15;
  color: #e5e5e5;
  cursor: pointer;
}
  .screen-switch-button {
    display: flex;
    width: 30px;
    height: 30px;
    background-color: #20202a;
    border: 1px solid #e5e5e5;
    box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, .75);
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
    z-index: 15;
    cursor: pointer;
  }

  .selected-layer {
    background: $secondaryColor;
    border: 1px solid $primaryColor;
    border-radius: 6px;
    position: absolute;
    top: 56px;
    left: 16px;
    pointer-events: all;
    overflow: auto;
    padding: 5px 10px;
    color: white;
  }

  .is-map-mini-screen {
    z-index: 10;
    position: absolute;
    right: 0;
    bottom: 44px;
    width: 200px !important;
    max-width: 200px !important;
    min-width: 200px !important;
    height: 200px !important;
    margin-right: 15px;
    margin-bottom: 10px;
    border: 4px solid #20202a;
  }

  .is-pano-mini-screen {
    z-index: 10;
    position: absolute;
    right: 0;
    bottom: 44px;
    width: 200px !important;
    height: 200px !important;
    margin-right: 15px;
    margin-bottom: 10px;
    border: 4px solid #20202a;
  }
}