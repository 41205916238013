@import '../../../index.scss';

.pano {
    // max-width: 100%;
    // max-height: 100%;
    // display: flex;
    // margin: 0;
    // position: absolute !important;
    // height: 95.1% !important;
    // width: 100%;

    canvas {
        max-width: 100%;
    }

    .pano-draw-button {
        position: absolute;
        top: 5px;
        left: 5px;

        .button-primary {
            width: 46px;
        }
    }

    .pano-draw-button-group {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        position: absolute;
        left: 5px;
        top: 50px;

        .button-group {
            margin-bottom: 5px;

            .button-primary {
                width: 46px;
                height: 42px;
            }
        }
    }


}

.pano-multi-display-active {
    // max-width: 100%;
    // max-height: 100%;
    // display: flex;
    // margin: 0;
    // position: absolute !important;
    height: 100% !important;
    // width: 100%;

    canvas {
        max-width: 100%;
    }
}

.pano-not-available {
    // background-color:"#17212f"
    z-index: 10 !important;
    position:relative!important;
    background-color:#304562;
    width:100%;
    height:100%;
    i {
        margin: 22% 0 0 40%;
    }
    .unauthorized-view {
        display: flex!important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 15%;
        margin-right: 5%;
        width: 100%;
        h3 {
            font-size: 1rem;
            color: #17212f;
            margin-right: 20%;
        }
    }
}

.pano-button-group3 {
    position: absolute;
    z-index: 9;
    top: 30px;
    right: 0px;
}
.pano-button-group {
    position: absolute;
    z-index: 9;
    top: 0;
    right: 0;

    .brightness {
        border-radius: 6px;
        background-color: $secondaryColor;
        top: 10px;
        position: absolute;
        right: 15px;
        padding: 4px;
        min-width: 27px;
        height: 27px;
        display: flex;
        align-items: center;
        border: 1px solid $primaryColor;

        &:hover {
            cursor: pointer;
        }
    }

    .brightness2 {
        border-radius: 6px;
        background-color: $secondaryColor;
        top: 10px;
        position: absolute;
        right: 15px;
        padding: 4px;
        min-width: 164px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $primaryColor;

        &:hover {
            cursor: pointer;
        }
    }

    .view {
        border-radius: 6px;
        background-color: $secondaryColor;
        top: 45px;
        position: absolute;
        right: 15px;
        padding: 4px;
        min-width: 27px;
        height: 27px;
        display: flex;
        border: 1px solid $primaryColor;

        &:hover {
            cursor: pointer;
        }
    }

    .view2 {
        border-radius: 6px;
        background-color: $secondaryColor;
        top: 45px;
        position: absolute;
        right: 15px;
        padding: 4px;
        min-width: 164px;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $primaryColor;

        &:hover {
            cursor: pointer;
        }
    }

    .lidar {
        border-radius: 6px;
        background-color: $secondaryColor;
        top: 78px;
        position: absolute;
        right: 15px;
        padding: 4px;
        min-width: 27px;
        height: 27px;
        display: flex;
        border: 1px solid $primaryColor;

        &:hover {
            cursor: pointer;
        }
    }

    .lidar2 {
        border-radius: 6px;
        background-color: $secondaryColor;
        top: 78px;
        position: absolute;
        right: 15px;
        padding: 4px;
        min-width: 53px;
        height: 27px;
        display: flex;
        justify-content: space-between;
        border: 1px solid $primaryColor;
        width: 164px;

        &:hover {
            cursor: pointer;
        }

        .label {
            display: flex;
            align-items: center;

            .text {
                margin: 5px;
            }
        }

        .p-checkbox {
            width: 17px !important;
            height: 17px !important;
        }

        .p-checkbox .p-checkbox-box .p-checkbox-icon {
            font-size: 11px !important;
        }

        .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box {
            width: 17px !important;
            height: 17px !important;
        }
    }

    .download {
        border-radius: 6px;
        background-color: $secondaryColor;
        top: 110px;
        position: absolute;
        right: 15px;
        padding: 4px;
        min-width: 27px;
        height: 27px;
        display: flex;
        border: 1px solid $primaryColor;

        &:hover {
            cursor: pointer;
        }
    }

    .settings {
        border-radius: 6px;
        background-color: $secondaryColor;
        top: 143px;
        position: absolute;
        right: 15px;
        padding: 4px;
        min-width: 27px;
        height: 27px;
        display: flex;
        border: 1px solid $primaryColor;

        &:hover {
            cursor: pointer;
        }
    }

    .settings-container {
        width: 280px;
        border-radius: 6px;
        background-color: $secondaryColor;
        top: 146px;
        position: absolute;
        right: 55px;
        padding: 4px 8px;
        display: flex;
        flex-direction: column;
        border: 1px solid $primaryColor;
        display: flex;

        .line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            font-size: 12px;
        }

        label {
            min-width: 80px;
            color: $textColor;
        }

        .p-slider {
            width: 95px;
        }

        .p-slider .p-slider-handle {
            width: 1em !important;
            height: 1em !important;
        }

        .p-slider.p-slider-horizontal .p-slider-handle {
            margin-top: -0.4815rem !important;
        }

        .p-inputtext {
            width: 50px;
            height: 25px;
            font-size: 12px;
        }

        .button-container {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 10px;

            .download-button {
                width: 100px !important;
                height: 25px !important;
                color: white;
            }
        }
    }
  
    .lidar-container {
        width:300px;
        border-radius: 6px;
        background-color: $secondaryColor;
        top: 106px;
        position: absolute;
        right: 60px;
        padding: 6px 8px;
        display: flex;
        flex-direction: row;
        border: 1px solid $primaryColor;
        display: flex;
        justify-content: space-between;
        height: 155px;
        .tooltip-image{
            .text{
                margin-left: 12px;
                color: white;
            }
            padding: 4px;
            width:120px;
        }

        .tooltip-image:after {
            content: "";
            background-color: #64B5F6;
            position: absolute;
            width: 1.4px;
            height: 124px;
            top: 7px;
            left: 48%;
            display: block;
          }

        .right-block{
            height: 84px;
            margin-left: 28px;
            margin-top: 10px;
            line-height: 1.3;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .tooltip-image2{
                width:160px;
                .text2{
                    margin-left: 1%;
                    color: white;
                    font-size: 13px;
                    
                }
                .checkBox
                    {
                        margin-left: 10%;
                    }
            }
        }

        .line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 4px;
            font-size: 12px;
            margin: 8px;
        }

        label {
            min-width: 80px;
            color: $textColor;
        }

        .p-slider {
            width: 95px;
        }

        .p-slider .p-slider-handle {
            width: 1em !important;
            height: 1em !important;
        }

        .p-slider.p-slider-horizontal .p-slider-handle {
            margin-top: -0.4815rem !important;
        }

        .p-inputtext {
            width: 50px;
            height: 25px;
            font-size: 12px;
        }

        .button-container {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 10px;

            .download-button {
                width: 100px !important;
                height: 25px !important;
                color: white;
            }
        }

    }


   
}


.pano-share-group{
    .share-container {
        width: 280px;
        border-radius: 6px;
        background-color: #fff;
        top: 6px;
        position: absolute;
        right: 55px;
        padding: 4px 8px;
        display: flex;
        flex-direction: column;
        border: 1px solid $primaryColor;
        display: flex;
        z-index: 15;
        .line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            font-size: 12px;
        }

        label {
            min-width: 80px;
            color: $textColor;
        }

        .p-slider {
            width: 95px;
        }

        .p-slider .p-slider-handle {
            width: 1em !important;
            height: 1em !important;
        }

        .p-slider.p-slider-horizontal .p-slider-handle {
            margin-top: -0.4815rem !important;
        }

        .p-inputtext {
            width: 50px;
            height: 25px;
            font-size: 12px;
        }

        .button-container {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 10px;

            .download-button {
                width: 100px !important;
                height: 25px !important;
                color: white;
            }
        }
    }

    
    .share-container {
        // width: 50%;
        // left: 26%;
        padding: 0px 28px 5px 30px; // 8px 24px 13px 30px;// 15px 20px 20px 20px;
        .header {
          color: #6b6c6e;
          margin-bottom: -18px;
          font-weight: bold;
          font-size: 14px;
        }
        .body {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          input[type="text"] {
            width: 100%;
            height: 30px;
            border: 1px solid #e1e1e1;
          }
          div.copy-link {
            display: flex;
            flex-direction: row;
            width: 106%;
            color: "#FFFFFF";
            margin-left: 10px;
            font-size: 10px;
            font-weight: bold;
            margin-top: 5px;
          }
    
          .social-media-icons {
            display: flex;
            margin-top: 20px;
          }
          .copylink-group {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 70%;
            // margin-top: 40px;
            margin-bottom: 0px;
          }
        }
    
        .close-button {
          position: absolute;
          right: 0;
          top: 0;
          font-size: 25px;
          opacity: 1;
          color: #9a9a9a;
          border: none;
          background-color: white;
        }
      }
}