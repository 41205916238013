@import '../../../index.scss';

.p-dialog{
    .p-dialog-title{
        font-size: 1.1rem !important;
    }
    .line{
        display: flex;
        align-items: center;
        min-width: 100%;
     /*    width: 250px; */
        .label{
            min-width: 112px;
            font-weight: 600;
        }
        .p-disabled, .p-component:disabled{
            opacity: 1 !important;
            min-width: 200px !important;
        }
        .p-inputnumber {
            width: 100%;
        }
        .p-colorpicker-overlay {
            /* padding-left: 20px; */
            position: relative;
            margin-left: -30px;
            margin-top: 3px;
        }
    }
    .attachment-line{
        display: flex;
        .label{
            min-width: 100px;
            font-weight: 600;
        }
        .p-disabled, .p-component:disabled{
            opacity: 1 !important;
            min-width: 200px !important;
        }
        .file-group{
            display: flex;
        }
    }
   
    .file-box {
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        align-items: center;
        width: 200px;
        .image-container{
            display:flex;
            flex-direction: column;
            align-items: center;
            .file-name{
                word-break: break-all;
                padding-left: 5px;
                font-size: 12px;
                text-align: center;
            }
            .button-container {
              color: $primaryColor;
              text-decoration: underline;
              font-size: 13px;
              &:hover{
                  cursor: pointer;
              }
            }
            .p-image {
                margin-top: 18px;
                img{
                    width: 200px !important;
                }
            }
        }
        .file-container{
            display:flex;
            flex-direction: column;
            align-items: center;
            .file-name{
                word-break: break-all;
                padding-left: 5px;
                font-size: 12px;
                text-align: center;
                margin-top: 10px;
            }
            .button-container {
              /* color: $primaryColor; */
              text-decoration: none;
              background-color: transparent;
              font-size: 13px;
              display: flex;
              justify-content:  space-between;
             
              &:hover{
                  cursor: pointer;
              }
              .full-screen-button {
                background-color: #007bff;
                color: white;
                border: none;
                padding: 8px 8px;
                cursor: pointer;
                margin-top: 8px;
                border-radius: 5px;
            }
            
            .full-screen-button:hover {
                background-color: #0056b3;
            }
            .download-btn {
                background-color: #007bff;
                color: white;
                border: none;
                padding: 8px 8px;
                cursor: pointer;
                margin-top: 8px;
                border-radius: 5px;
                
            }
            }
            .p-image {
                margin-top: 18px;
                img{
                    width: 30px !important;
                }
            }
        }
    }

   
    
}
