@import 'primereact/resources/themes/vela-blue/theme.css'; //theme
// @import 'primereact/resources/themes/bootstrap4-dark-blue/theme.css'; //theme
@import 'primereact/resources/primereact.min.css'; //core css
@import 'primeicons/primeicons.css';

$primaryColor: #64B5F6;
$secondaryColor: #17212f;
$thirdColor: #1f2d40;

$primaryHoverColor: rgb(136, 10, 83);
$secondaryHoverColor: rgba(136, 10, 83, 0.582);

$secondaryFocusColor: rgb(136, 10, 83);
$darkBackgroundColor: #64B5F6;
$lightbackgroundColor: #17212f;
$darkbackgroundColor: #f5f5f5;
$textColor: #f5f5f5;
$borderColor: #f5f5f5;

body,
html,
#root {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-size: 14px;
  user-select: none;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  background: $lightbackgroundColor;
}


// PRINT CONF START
.only-printable {
  // display: none;
  visibility: hidden !important;
  opacity: 0;
  transition: visibility 2s, opacity 0.25s linear;
}

.sidebar-start{
  .p-datatable-resizable > .p-datatable-wrapper {
    overflow-x: scroll !important;
    max-height: calc(100vh - 30px) !important;
    height: calc(100% - 30px) !important;
    margin-top: -1px;
    width: 100%;
  }
}

.print-start {
  .containers {
    // height: calc(100% - 306px) !important;
    height: 100% !important;
    background-color: rgba(255, 255, 255, 1);
  }

  .table-opened {
    visibility: visible !important;
    // height: 255px !important;
    position: absolute !important;
    width: 100% !important;
    // bottom: 47px !important;
    bottom: 2px !important;
    z-index: 9999999999999 !important;
  }
  .p-datatable-resizable > .p-datatable-wrapper {
      overflow-x: scroll !important;
      max-height: 100vh !important;
      height: 100% !important;
      margin-top: -1px;
      width: 100%;
  
    // overflow-x: auto !important;
    background-color: #17212F !important;
  }
  .non-printable {
    // display: none !important;
    visibility: hidden !important;
    opacity: 0;
    height: 0px !important;
    padding: 0 !important;
    transition: visibility 2s, opacity 0.25s linear;
  }

  .only-printable {
    display: flex !important;
  }

  anka-pan canvas {
    width: 100% !important;
    height: 100% !important;
  }

  &.only-map {
    .hsplitter>.vsplitterPano {
      // display: none !important;
      visibility: hidden !important;
      opacity: 0;
      transition: visibility 2s, opacity 0.25s linear;
    }

    .hsplitter>.vsplitterMap {
      width: 100%;
    }
  }

  &.only-panorama {
    .hsplitter>.vsplitterMap {
      // display: none !important;
      visibility: hidden !important;
      opacity: 0;
      transition: visibility 2s, opacity 0.25s linear;
    }

    .hsplitter>.vsplitterPano {
      width: 100%;
    }
  }
}


//   ---------> BURADA YAZILAN KOD TÜM DİALOG'LARI(MODALLARI) ETKİLİYOR. BUNLAR OLUŞMASIN DİYE CLASSNAME ÖZELLİĞİ VEREBİLİYORUZ.
//   ---------> LÜTFEN BUNU YAPMAKTAN VAZGEÇELİM. TEŞEKKÜRLER.    -SALİHA SOYTÜRK
.pane .vertical .x{
  position: relative !important;
}
.pano-button-group2{
  position: absolute !important;
  left: 15px !important;
  bottom: 180px !important;
}
// .pano-button-group2{
//    position: absolute !important;
//    margin-bottom: 70px !important;
//    z-index: 9 !important;
//    bottom: 45px !important;
//    right: 55px !important;
// }

.pano-button-group3{
  position: absolute !important;

  margin-top: 0px !important;
 
}
.pano-button-group {
  position: absolute !important;

  margin-top: 30px !important;
}

.pano-share-group {
  // position: absolute !important;

  margin-top: 0px !important;
}
@media only screen and (max-device-width: 680px) {
  /* Styles */
  .pane .vertical .x{
    position: static !important;
  }
  .non-visible {
     display: none !important;
    visibility: hidden !important;
    height: 0px !important;
    padding: 0px !important;
    transition: visibility 2s, opacity 0.25s linear;
  }
  .handle-bar {
    /*Splittter Bar */
    display: none !important;
    height: 0px !important;
    width: 0px !important;
    visibility: hidden !important;
  }
  .sidebar-box{
    display:flex;
    align-items: center;
    justify-content: center;
    // flex-wrap: wrap;
    min-width:40px !important;
}
  .home {
    .navbar{
      padding : 8px 10px 2px 6px !important;
    }
  // .navbar {
  //   display: none !important;
  //   height: 0px !important;
  //   width: 0px !important;
  //   visibility: hidden !important;
  // }
  .pano-button-group {
    position: absolute !important;
  
    margin-top: 60px !important;
  }

  .pano-button-group2{
/*     z-index: 9 !important;
    bottom: 45px !important;
    left: 0 !important;
    margin-bottom: 30px !important; */

    z-index: 9 !important;
    bottom: 45px !important;
    left: 6px !important;
    margin-bottom: 76px !important;


  }
//   .mobile-switch-button2 {
//     left: 0px !important;
//     right: 10px !important;
  
// }
  
  /*   .share-container{
    // position: absolute !important;

    // margin-top: 40px !important;
   
  } */
}

.digi-button-box{
  display: flex;
  flex-wrap: wrap;
  min-width: 56px !important;
  justify-content: center;
}


.p-button {
  background-color: $secondaryColor !important;
  border: 1px solid $borderColor;
  // color: $primaryColor;
  transition: none;
  width: 28px !important;
  height: 28px !important;
  padding: 0.001em !important;
  margin: 0 0.2em !important;
  

  &:enabled:hover {
    background-color: transparent;
    color: $primaryColor;
    border: 1px solid $primaryColor;
  }

  &:focus {
    box-shadow: none;
    background-color: transparent;
  }

  &:active {
    box-shadow: none;
    background-color: transparent !important;
  }
}

}

@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  .non-printable {
    // display: none !important;
    visibility: hidden !important;
    opacity: 0;
    height: 0px !important;
    padding: 0 !important;
    transition: visibility 2s, opacity 0.25s linear;
  }

  .only-printable {
    display: flex !important;
    // visibility: visible;
    opacity: 1;
    // visibility: hidden !important;
    // opacity: 0;
    // transition: visibility 2s, opacity 0.25s linear;
  }
}

// PRINT CONF END

// .p-button:enabled:active {
//   background-color: $primaryColor !important;
//   color: $secondaryColor;
//   border: 2px solid $primaryColor !important;
// }

.p-button {
  background-color: $secondaryColor !important;
  border: 1px solid $borderColor;
  // color: $primaryColor;
  transition: none;
  width: 30px;
  height: 30px;
  padding: 0.1em !important;
  margin: 0 0.4em;

  &:enabled:hover {
    background-color: transparent;
    color: $primaryColor;
    border: 1px solid $primaryColor;
  }

  &:focus {
    box-shadow: none;
    background-color: transparent;
  }

  &:active {
    box-shadow: none;
    background-color: transparent !important;
  }
}

.logout-confirm{
  button{
    margin-left: 10px !important;
    &:hover{
      border-color: #64B5F6 !important;
  
    }
  }  
}

//password input şifrenin güçlü mü zayıf mı olduğunu gösteren kısım
.p-password-panel {
  display: none;
}

//button tıklandığında eklenen css
.active-button {
  background-color: #64b5f6b0 !important;
}

.go-to-location-active-button {
  padding-left: 5px !important;
  width: 33px;
  height: 33px;
  background-color: #64b5f6b0 !important;
  cursor: pointer;
}

.go-to-location-button {
  padding-left: 5px !important;
  width: 33px;
  height: 33px;
  cursor: pointer;
}

.p-splitter {
  width: 100%;
  border-radius: 0;
}

.p-splitter-gutter {
  background-color: $primaryColor !important;
}

.p-splitter-gutter-handle {
  background-color: $primaryColor !important;
}

// .pi {
//   font-weight: bold;
// }

// .p-button-label {
//   font-size: 14px;
//   font-weight: bold;
// }

// .p-menubar-start {
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
// }

// .p-slider-handle {
//   border: 2px solid $secondaryColor !important;
//   &:hover {
//     background: $secondaryColor !important;
//   }
//   &:focus {
//     box-shadow: 0 0 0 0.2rem $primaryHoverColor !important;
//   }
// }

// .p-slider .p-slider-range {
//   background: $secondaryColor;
// }

// sidebar icin
.p-tree {
  border: none !important;
  padding: 5px !important;
  overflow-y: auto;
}

.p-tree .p-tree-container .p-treenode {
  color: #000000;
  font-weight: 400;
  font-size: 15px;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  &:focus {
    box-shadow: 0 0 0 0.2rem #dee2e680;
  }
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: transparent;
  color: #495057;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler {
  color: $primaryColor;

  &:hover {
    color: $primaryColor;
  }
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: $primaryColor;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  &:focus {
    box-shadow: none;
  }
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  box-shadow: none;
}

//checkbox style
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box {
  border: 2px solid #ced4da;
  box-shadow: none !important;
  border-color: none !important;

  &:hover {
    border: 2px solid $secondaryColor;
    box-shadow: none;
  }

  &:focus {
    border: 2px solid $secondaryColor;
    box-shadow: none;
  }
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: $primaryColor !important;
  border: 2px solid $primaryColor !important;
  border-color: $primaryColor !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight {
  border: 2px solid $primaryColor;
  box-shadow: none;
  border-color: $primaryColor !important;
  color: $primaryColor;

  &:hover {
    border-color: $primaryColor;
    background: $primaryColor;
    color: $primaryColor;
  }
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border: 2px solid $primaryColor;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border: 2px solid $primaryColor !important;
  border-color: $primaryColor !important;
}

//toast
.p-toast {
  z-index: 100 !important;
  bottom: 40px !important;
}

//dialog close button
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  box-shadow: none !important;
}

//dialog
.p-dialog-header {
  padding: 8px 1em !important;
  color: $primaryColor !important;
  border-bottom: 1px solid $primaryColor !important;
  border-radius: 0 !important;
}

.p-dialog-content {
  padding: 1rem !important;
}

.dialog-box {
  width: 18vw;
  // height: 38vh;
  max-height: 610px;
  position: absolute;
  top: 45px;
  left: 4px;
  min-width: 335px !important;
  border: 1px solid $primaryColor !important;
}

.dialog-box-sidebar-open {
  width: 18vw;
  // height: 38vh;
  max-height: 610px;
  position: absolute;
  left: 265px;
  top: 45px;
  min-width: 335px !important;
  border: 1px solid $primaryColor !important;
}

//tooltip
.custom-tooltip.p-tooltip .p-tooltip-text {
  background-color: white;
  color: $primaryColor;
  border: 1px solid $primaryColor;
  padding: 3px 5px !important;
}

// .p-tree-container {
//   overflow: hidden;
// }
// .p-tree .p-tree-container .p-treenode .p-treenode-content {
//   padding: 5px;
// }

// .p-sidebar .p-sidebar-header .p-sidebar-close:focus,
// .p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
//   // box-shadow: 0 0 0 0.1rem #6c757d;
//   box-shadow: none;
// }
// .p-sidebar .p-sidebar-content {
//   padding: 1.25rem 0 1.25rem 1.25rem;
//   overflow: hidden;
// }
// //sidebar bitti

// .p-datepicker.p-component.p-connected-overlay-enter-done {
//   // z-index: 1000001000 !important;
// }

// //kategorik arama icin
// .p-treeselect:not(.p-disabled):hover {
//   border-color: #ced4da;
// }
// .p-treeselect:not(.p-disabled).p-focus {
//   box-shadow: 0px 0px 5px 1.5px #ced4da;
//   border-color: #ced4da;
// }
// .p-treeselect-header {
//   display: none;
// }
// //kategorik arama bitti

// //hizli arama icin
// .p-inputtext:enabled:hover {
//   border-color: #ced4da;
// }
// .p-inputtext:enabled:focus {
//   box-shadow: 0px 0px 5px 1.5px #ced4da;
//   border-color: #ced4da;
// }
// .p-autocomplete-panel {
//   max-width: 310px;
// }
// .p-autocomplete-item {
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }
// .p-autocomplete .p-autocomplete-loader {
//   display: none;
// }
// //hizli arama bitti

// // sidebar yazılar sola yaslandı
// .p-treenode-label {
//   text-align: start;
//   font-size: 14px;
//   font-weight: 400;
//   overflow: hidden;
//   text-align: left;
// }
// // sidebar yazılar sola yaslandı bitti

// .p-treeselect .p-treeselect-label {
//   text-align: left;
// }
// //dil secenegi bitti

// //draw button clicked
// .active-draw-button {
//   background-color: $primaryHoverColor;
// }

// .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
//   display: flex;
//   align-items: center;
// }

// .p-checkbox .p-checkbox-box.p-highlight {
//   width: 20px !important;
//   height: 20px !important;
// }

// .long-button {
//   width: 100%;
// }

// scroll style
/* width */
::-webkit-scrollbar {
  width: 6px;
  border-radius: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $secondaryColor;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primaryColor;
  border-radius: 6px;
}

//table
.p-datatable-wrapper {
  font-size: 14px;
  overflow: auto;
  width: auto;
  height: 300px;

  .p-datatable-thead {
    tr {
      // position: sticky !important;
      white-space: nowrap;
      // height: 10px !important;

      th {
        // width: 10px !important;
        // background-color: $secondaryColor !important;
        position: sticky;

      }
    }
  }
}

.p-datatable table {
  border-collapse: collapse;
}

.p-datatable .p-datatable-tbody>tr {
  font-weight: 500;
  overflow: hidden;
  text-align: left;
}

.p-datatable .p-datatable-tbody>tr>td {
  min-width: 50px !important;
  max-width: 50px !important;
  min-height: 25px !important;
  max-height: 25px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  overflow: hidden;
  padding: 0.4rem 0rem 0.4rem 0.8rem;
  // border-color: $textColor !important;
}

.p-resizable-column>.p-frozen-column>.p-selection-column {
  background: pink !important;
}

.p-datatable .p-datatable-tbody>tr>td:hover {
  cursor: pointer;
}

.p-datatable-responsive-scroll>.p-datatable-wrapper>table,
.p-datatable-auto-layout>.p-datatable-wrapper>table {
  table-layout: unset;
  // table-layout: auto;
}

.p-datatable .p-datatable-thead>tr>th {
  position: -webkit-sticky;
  position: sticky !important;
  top: 0px;
  z-index: 1;
  padding: 0.4rem 0.4rem 0.4rem 0.8rem;
  background-color: #42637e !important;
  // border-color: $textColor !important;
}

.p-datatable-resizable>.p-datatable-wrapper {
  overflow-x: scroll !important;
  max-height: 205px;
}

.p-datatable[pr_id_5] .p-datatable-thead>tr>th:nth-child(1),
.p-datatable[pr_id_5] .p-datatable-tbody>tr>td:nth-child(1),
.p-datatable[pr_id_5] .p-datatable-tfoot>tr>td:nth-child(1) {
  max-width: 45px !important;
  width: 45px !important;
  min-width: 45px !important;
}

.p-datatable-resizable .p-datatable-resizable-column {
  // position: sticky !important;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-row-odd.p-highlight {
  // background: #ffecec;
  // color: $primaryColor;
}

.p-datatable.p-datatable-striped .p-datatable-tbody>tr.p-highlight {
  // background: #ffecec;
  // color: $primaryColor;
}

.p-datatable.p-datatable-selectable .p-datatable-tbody>tr.p-selectable-row:focus {
  outline: 0.15rem solid $primaryHoverColor;
}


// //context menu
// .p-contextmenu {
//   width: 150px;
//   box-shadow: black 0px 0px 5px;
// }

// .p-contextmenu .p-menuitem-link {
//   padding: 10px 0px 10px 10px;
// }

// .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
//   background: $primaryHoverColor;
// }

// .p-contextmenu
//   .p-menuitem.p-menuitem-active
//   > .p-menuitem-link
//   .p-menuitem-text {
//   color: $secondaryHoverColor;
//   // font-weight: 500;
// }

// //paginator
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  // background: $textColor;
  // color: $secondaryColor;
  box-shadow: none;
  border-radius: 50%;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 2rem;
  height: 2rem;
}

.p-paginator {
  padding: 0.25rem;
}

.p-datatable .p-datatable-loading-overlay {
  .pi-spinner {
    z-index: 100 !important;
    color: $primaryColor;
  }
}

// .pi-sign-out {
//   font-size: 17px !important;
// }

// // .p-toast {
// //   width: auto;
// // }

// //print css
// @media print {
//   //hizli arama listesi icin
//   .p-autocomplete-panel {
//     left: 300px !important;
//     max-width: 225px !important;
//     min-width: 225px !important;
//   }
// }

//slider style
input[type=range].styled-slider {
  cursor: grab;
  accent-color: $primaryColor;
  height: 6px;
}

//dropdown style
.p-dropdown {
  border: 1px solid $borderColor !important;
  min-width: 128px;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
  border: 1px solid $primaryColor !important;
  outline: none !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.3rem 0.5rem !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: $primaryColor;
}

// .p-dropdown .p-dropdown-label.p-placeholder, .p-dropdown-label {
//   padding: 5px 0px 4px 8px !important;
// }

.p-dropdown-items {
  padding: 1 !important;
}


.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin: 0px !important;
}

.p-treenode-icon {
  width: 30px !important;
  height: 30px !important;
}

.splitter {
  width: 100%;
  // height: 95.1%;
}

.splitter.horizontal.aa.bb {
  height: 95.1% !important;
}

.handle-bar {
  height: 100% !important;
  background: $primaryColor;
  width: 5px;
  min-width: 5px;

  &:hover {
    background: $primaryColor;
  }
}

.handle-bar.horizontal {
  height: 5px !important;
  min-height: 5px !important;
}

.screenshot {
  .p-button {
    margin: 0;

    .pi-times,
    .pi-download {
      color: var(--text-color);
    }
  }
}

//for sidebar context menu
.hidden-context-menu {
  display: none;
}

.panel-dropdown {
  max-width: 200px !important;
  min-width: 200px !important;
  width: 200px !important;
}

.panel-dropdown .p-dropdown-items .p-dropdown-item {
  padding: 0.5rem 1rem !important;
}